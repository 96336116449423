import { Injectable } from '@angular/core';
import * as nodemailer from 'nodemailer';
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class MailService {

  envoiMail(url, data) {
    return this.http.post(url, data);
  }

  constructor(private http: HttpClient) { }
}
