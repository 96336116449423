import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {
  isFormulaire = false;

  constructor() { }
  ngOnInit(): void {
  }
  onFormulaire() {
   this.isFormulaire = true;
  }
  onCarte() {
    this.isFormulaire = false;
  }

}
