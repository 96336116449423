<div class="container">
  <div class="row shadow bg-white mt-lg-5 p-3">
    <div class="col-lg-6 col-12 text-center">
      <h3 class="font-weight-bold">Dauphiné dentaire</h3>
      <h4>Cabinet dentaire à Echirolles</h4>

      <div class="row mt-5">
        <div class="col-2 d-none d-lg-block"></div>
        <div class="col-lg-3 col-6 text-center">
          <img src="assets/dr-gunzburger-alice.jpg" alt="dr-gunzburger-alice" class="img-fluid">
          Dr Alice Gunzburger<br>
        </div>
        <div class="col-2 d-none d-lg-block"></div>
        <div class="col-lg-3 col-6 text-center">
          <img src="assets/dr-anh-tuan-nguyen.jpg" alt="dr-anh-tuan-nguyen" class="img-fluid">
          Dr Anh Tuan Nguyen<br>
        </div>
        <div class="col-2 d-none d-lg-block"></div>

      </div>
      <hr>
      14 r Paul Langevin • 38130 ECHIROLLES<br><br>
      <a href="tel:0476223219" class="btn btn-outline-info w-50 mb-4"><i class="fas fa-mobile-alt fa-2x"></i><br>04 76 22 32 19</a>
      <br>
      <button class="btn btn-outline-info w-50 mb-4" (click)="onFormulaire()" *ngIf="!isFormulaire">
        <i class="far fa-envelope-open fa-2x"></i><br>
        Formulaire de contact
      </button>
      <button class="btn btn-outline-info w-50 mb-4" (click)="onCarte()" *ngIf="isFormulaire">
        <i class="far fa-map fa-2x"></i><br>
        Afficher la carte
      </button>
    </div>

    <div class="col-lg-6 col-12" *ngIf="isFormulaire">
      <app-formulaire></app-formulaire>
    </div>

    <div class="col-lg-6 col-12" *ngIf="!isFormulaire">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2814.0618496357824!2d5.723147015541571!3d45.1453434790985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478a8b380f10db5d%3A0xbe563cc61e9d35e0!2s14%20Rue%20Paul%20Langevin%2C%2038130%20%C3%89chirolles!5e0!3m2!1sfr!2sfr!4v1587826081145!5m2!1sfr!2sfr" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <img src="assets/accueil.jpg" alt="dr-anh-tuan-nguyen" class="img-fluid mt-2">
    </div>
  </div>
</div>
