<h3>Formulaire de contact</h3>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" *ngIf="!isComplet">
  <div class="form-group">
    <label for="nom">Nom et prénom</label>
    <input type="text" id="nom" name="nom" class="form-control" placeholder="Nom et prénom" ngModel required>
  </div>
  <div class="form-group">
    <label for="telephone">Numéro de téléphone</label>
    <input type="text" id="telephone" name="telephone" class="form-control" placeholder="Ex. : 06 12 34 56 78" ngModel required  pattern="[0-9]{10}">
  </div>
  <div class="form-group">
    <label for="email">Email</label>
    <input type="email" id="email" name="email" class="form-control" placeholder="votrenom@gmail.com" ngModel required>
  </div>
  <div class="form-group">
    <label for="email">Votre message</label>
    <textarea name="message" id="message" class="form-control" ngModel></textarea>
  </div>
  <div class="form-group">
    <label for="destinataire">Destinataire</label>
    <select name="destinataire" id="destinataire" class="form-control" ngModel required>
      <option value="secretariat">secretariat</option>
      <option value="gunzburger">Dr Alice Gunzburger</option>
      <option value="nguyen">Dr Anh Tuan Nguyen</option>
    </select>
  </div>


  <button class="btn btn-outline-info w-50 mb-3" [disabled]="f.invalid">Valider</button>
</form>

<div class="border-left border-success p-5 mt-5" *ngIf="isComplet">
  <h2 class="text-success">
    <i class="far fa-check-circle fa-2x mr-3"></i>Merci !</h2>
  Nous avons bien pris votre message en compte.<br>
  Nous vous recontacterons dans les meilleurs délais.
</div>
