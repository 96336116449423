import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {MailService} from '../services/mail.service';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-formulaire',
  templateUrl: './formulaire.component.html',
  styleUrls: ['./formulaire.component.css']
})
export class FormulaireComponent implements OnInit {
  isComplet = false;

  onSubmit(f: NgForm) {
    this.mailService.envoiMail('assets/envoiMail.php', f.value).subscribe(
      (data) => {
        console.log('nous sommes ici !' + data);
      },
      err => {
        console.log(err);
      }, () => {
        console.log('la fin');
      }
    );
    console.log(f.value);
    // console.log(this.mailService.envoiMail());
    this.isComplet = true;
  }

  constructor(private mailService: MailService, private http: HttpClient) { }

  ngOnInit(): void {
  }

}
